import React, {useEffect, useState, useCallback} from 'react';
import * as pdfjs from 'pdfjs-dist';
import "./Flipbook.css";
import 'font-awesome/css/font-awesome.min.css';
import clickIcon from './easy-to-use-icon.svg';

function Flipbook({onLoadingChange}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pdf, setPdf] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const [dummyProperties, setDummyProperties] = useState({height: 0, width: 0});
    const [icons, setIcons] = useState([]);
    const renderedIcons = new Set();


    useEffect(() => {
        const container = document.getElementById('pdf-container');
        const PDF_PATH = '/dentalspot_1.pdf';

        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.10.111/pdf.worker.min.js`;

        pdfjs.getDocument(PDF_PATH).promise.then(async (loadedPdf) => {
            setPdf(loadedPdf);
            await renderPages(loadedPdf, container, 1);
            setLoading(false);
            onLoadingChange(false);
            setNumPages(loadedPdf.numPages);
        });
    }, []);

    const renderPages = async (pdf, container, pageNumber) => {
        container.innerHTML = '';
        setLoading(true);
        onLoadingChange(true);
        if (pageNumber === 1) {
            if (window.innerWidth >= 768) {
                renderEmptyPage(pdf, container);
            }
            await Promise.resolve(renderSinglePage(pdf, container, 1));
            setLoading(false);
            onLoadingChange(false);
        } else {
            let promises = [];  // Array to hold all rendering promises

            for (let i = 0; i < 2; i++) {
                if (window.innerWidth <= 768) { // Mobile breakpoint
                    if (pageNumber + i <= pdf.numPages) {
                        promises.push(renderSinglePage(pdf, container, pageNumber));
                    }
                    break;
                } else {
                    if (pageNumber + i <= pdf.numPages) {
                        promises.push(renderSinglePage(pdf, container, pageNumber + i));
                    } else {
                        promises.push(renderEmptyPage(pdf, container));
                    }
                }
            }

            await Promise.all(promises); // Wait for all promises to resolve
            setLoading(false);
            onLoadingChange(false);


        }
    };

    const renderEmptyPage = async (pdf, container) => {
        const canvas = document.createElement('canvas');
        const page = await pdf.getPage(1)
        container.appendChild(canvas);
        const viewport = page.getViewport({scale: window.innerHeight / page.getViewport({scale: 1.0}).height * 0.8});
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.backgroundColor = '#fff';
        setDummyProperties({height: viewport.height, width: viewport.width});
    };

    const renderSinglePage = async (pdf, container, pageNumber) => {
        const page = await pdf.getPage(pageNumber);
        const canvas = document.createElement('canvas');
        container.appendChild(canvas);
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({scale: window.innerHeight / page.getViewport({scale: 1.0}).height * 0.8});
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.maxWidth = '100%';
        await page.render({
            canvasContext: context,
            viewport: viewport,
        }).promise;

        try {
            icons.forEach(icon => container.removeChild(icon));
        } catch (e) {
            console.log(e);
        } finally {
        }
        setIcons([]);

        page.getAnnotations().then((annotations) => {
            canvas.style.cursor = 'default';

            annotations.forEach((annotation) => {
                if (renderedIcons.has(annotation.id)) {
                    return; // Skip this icon if it's already been rendered
                }
                const rect = annotation.rect;

                //draw a border for the rect
                // context.beginPath();
                // context.lineWidth = "1";
                // context.strokeStyle = "red";
                // context.rect(rect[0] * viewport.scale, viewport.height - rect[3] * viewport.scale, (rect[2] - rect[0]) * viewport.scale, (rect[3] - rect[1]) * viewport.scale);
                // context.stroke();

                const iconSize = 16; // or whatever your icon's size is
                const iconCenterX = (rect[2] + rect[0]) / 2 * viewport.scale;//center
                const iconCenterY = (rect[3] + rect[1]) / 2 * viewport.scale;//center
                // const iconCenterX = rect[2] * viewport.scale - iconSize; // Right edge minus icon width
                // const iconCenterY = viewport.height - rect[3] * viewport.scale; // Adjust for flipped y-axis and position just below top edge


                const img = new Image();
                img.onload = function() {
                    context.drawImage(img, iconCenterX - iconSize / 2, viewport.height - iconCenterY - iconSize / 2, iconSize, iconSize);
                }
                img.src = clickIcon; // Path to your FontAwesome icon image
                renderedIcons.add(annotation.id); // Mark this icon as rendered
            });
        });

        canvas.addEventListener('click', (e) => {
            const x = e.clientX - canvas.getBoundingClientRect().left;
            const y = e.clientY - canvas.getBoundingClientRect().top;

            page.getAnnotations().then((annotations) => {
                annotations.forEach((annotation) => {
                    const rect = annotation.rect;

                    const iconSize = 16;
                    const iconCenterX = (rect[2] + rect[0]) / 2 * viewport.scale;
                    const iconCenterY = viewport.height - (rect[3] + rect[1]) / 2 * viewport.scale;

                    const iconLeft = iconCenterX - iconSize / 2;
                    const iconRight = iconCenterX + iconSize / 2;
                    const iconTop = iconCenterY - iconSize / 2;
                    const iconBottom = iconCenterY + iconSize / 2;

                    if (x >= iconLeft && x <= iconRight && y >= iconTop && y <= iconBottom) {
                        window.open(annotation.url, '_blank');
                        return;
                    }
                });
            });
        });


    };
    // const renderSinglePage = useCallback(async (pdf, container, pageNumber) => {
    //     const page = await pdf.getPage(pageNumber);
    //     const canvas = document.createElement('canvas');
    //     container.appendChild(canvas);
    //     const context = canvas.getContext('2d');
    //     const viewport = page.getViewport({scale: window.innerHeight / page.getViewport({scale: 1.0}).height * 0.8});
    //     canvas.height = viewport.height;
    //     canvas.width = viewport.width;
    //
    //     await page.render({
    //         canvasContext: context,
    //         viewport: viewport,
    //     }).promise;
    //
    //     page.getAnnotations().then((annotations) => {
    //         annotations.forEach((annotation) => {
    //             // if (annotation.subtype === "Link") {
    //                 const rect = annotation.rect;
    //                 const icon = document.createElement("i");
    //                 icon.className = "fa fa-hand-pointer clickable-icon";
    //                 icon.style.position = "absolute";
    //                 icon.style.top = `${canvas.getBoundingClientRect().top + (viewport.height - rect[3] * viewport.scale)}px`;
    //                 icon.style.left = `${canvas.getBoundingClientRect().left + rect[0] * viewport.scale}px`;
    //                 icon.style.fontSize = '16px'; // Adjust as needed
    //                 icon.style.color = '#ff0000'; // Change to your preferred color
    //                 container.appendChild(icon);
    //             // }
    //         });
    //     });
    // }, []);

    const goToNextPage = async () => {
        if (pdf && currentPage < pdf.numPages) {
            let newPage;
            if(currentPage === 1){
                newPage = currentPage + 1;
            }else{
                newPage = currentPage + 2;
            }
            const container = document.getElementById('pdf-container');
            await renderPages(pdf, container, newPage);
            setCurrentPage(newPage);
        }
    };

    const goToPreviousPage = async () => {
        if (pdf && currentPage > 1) {
            const newPage = currentPage - 2;
            const container = document.getElementById('pdf-container');
            await renderPages(pdf, container, newPage);
            setCurrentPage(newPage);
        }
    };

    return (
        <div>
            <div className={currentPage + ' - ' + numPages} style={{display: "flex"}}>
                <div id="pdf-container" className={isLoading ? "is-loading" : "loaded"} style={{display: "flex", position: "relative"}}></div>
                {isLoading &&
                    <div style={{
                        height: `${dummyProperties.height}px`,
                        width: `${dummyProperties.width}px`,
                        backgroundColor: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // to mimic Paper's elevation effect
                        borderRadius: '4px', // rounded corners similar to Paper
                    }}
                         className="dummy-page">
                    </div>
                }
                {isLoading &&
                    <div style={{
                        height: `${dummyProperties.height}px`,
                        width: `${dummyProperties.width}px`,
                        backgroundColor: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                    }}
                         className="dummy-page">
                    </div>
                }


            </div>
            <div className="controls">
                <button  className="action previous fade-out-animation" onClick={goToPreviousPage} disabled={currentPage <= 1}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <button className="action next  fade-out-animation" onClick={goToNextPage} disabled={currentPage + 1 >= numPages}>
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
}

export default Flipbook;
