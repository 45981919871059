import logo from './logo.png';
import React, {useEffect, useState} from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import Flipbook from "./Flipbook";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
function App() {
    const [isLoading, setIsLoading] = useState(true);
    // In your main App component or any startup component:
    const [appSettings, setAppSettings] = useState({});

    useEffect(() => {
        fetch("/settings.json")
            .then(response => response.json())
            .then(data => {
                // Set your state or context or Redux store with the fetched data
                document.title = data.title;
                setAppSettings(data);
            });
    }, []);

    return (
        <ThemeProvider theme={createTheme()}>
            <Helmet>
                <title>{appSettings.title}</title>
                <meta name="description" content={`${appSettings.description}`} />
                <link rel="icon" href={appSettings.favicon} type="image/png" />
            </Helmet>
        <div className="App">
            <header className="header" style={{display:"flex",justifyContent:"center"}}>
                <div className="wrapper" style={{maxWidth:"1170px", width:"100%", display:'flex',justifyContent:"space-between"}}>
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                    {appSettings.phoneNumber && <div className="contact"><a href={`tel:${appSettings.phoneNumber}`}>{appSettings.phoneNumber}</a></div>}
                </div>
            </header>

            <main className={isLoading ? "main-content loading" : "main-content"} style={{display:"flex",alignItems:"center",flexGrow:1,justifyContent:"center"}}>

            <Flipbook onLoadingChange={setIsLoading} />
            </main>

            <footer className="footer" style={{display:"flex",justifyContent:"center"}}>
                <div className="wrapper" style={{maxWidth:"1170px", width:"100%", display:'flex',justifyContent:"space-between"}}>
                    {appSettings.footerText && <div className="footer-text">{appSettings.footerText}</div>}
                </div>
            </footer>
        </div>
        </ThemeProvider>
    );
}

export default App;
